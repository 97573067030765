import fetch from 'node-fetch'
import { getCID, getFBValidLink, getGaValidLink } from 'tracking'

export const sendForm = (
    formName: string,
    formData: object,
    placement?: string
) => {
    const place = placement ? placement : ''
    return (
        fetch(process.env.GATSBY_SEND_FORM_URL as string, {
            method: 'POST',
            body: JSON.stringify({
                ...formData,
                formName,
                place,
                page: location?.href,
                cid: getCID(),
                fbpLink: getFBValidLink(),
                GaValid: getGaValidLink(getCID()),
            }),
            headers: {
                'Content-type': 'application/json',
            },
        })
            .then((response) => {
                return response.json()
            })
            // eslint-disable-next-line no-console
            .catch((err) => console.log(err))
    )
}
