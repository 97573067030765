import React from 'react'
import styled from 'styled-components'

import { colors } from 'styles/colors'
import CloseIcon from 'assets/icons/Exit.svg'
import { displayWidth } from 'styles/width'

const ModalWrapper = styled.div<{ open: boolean }>`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 30;
`
const ModalWindow = styled.div<{ image: boolean }>`
    position: relative;
    max-width: 100%;
    max-height: 90%;
    z-index: 40;
    ${({ image }) =>
        image
            ? 'padding: 0;background-color: transparent;border: none;width:90%;max-width: 90%;overflow: none;'
            : `padding: 40px ;background-color: ${colors.white}; border: 1px solid ${colors.dark};overflow-y: auto;`};

    box-sizing: border-box;
    justify-content: center;
    @media (min-width: ${displayWidth.tablet}) {
        max-width: 70%;
        ${({ image }) => image && ` max-width: 90%;`};
        max-height: 90%;
    }
    @media (orientation: portrait) and (min-width: ${displayWidth.tablet}) {
        width: 80%;
    }
    @media (min-width: ${displayWidth.desktop}) {
        ${({ image }) => (image ? `height: 100%;` : `max-height: 90%;`)};
    }
    @media (orientation: landscape) and (max-height: 700px) {
        padding: 50px 70px;
        justify-content: center;
    }
`
const CloseIconStyled = styled(CloseIcon)<{ $image: boolean }>`
    position: absolute;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 4;
    ${({ $image }) =>
        $image
            ? `fill: ${colors.white};stroke:${colors.dark};`
            : `fill: ${colors.dark};stroke:${colors.white};`};
`
interface IModalProps {
    closeHandler?: () => void
    children: React.ReactElement
    isOpen: boolean
    image?: boolean
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slidderRef?: any
}

export const Modal: React.FC<IModalProps> = ({
    closeHandler,
    children,
    isOpen = false,
    image = false,
    slidderRef,
}) => {
    const stopPropagation = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.stopPropagation()
    }

    return (
        <ModalWrapper open={isOpen} onClick={closeHandler}>
            <ModalWindow
                image={image}
                onClick={stopPropagation}
                tabIndex={0}
                onKeyDown={(e: { key: string }) => {
                    if (e.key === 'ArrowRight') {
                        slidderRef?.slickNext()
                    }

                    if (e.key === 'ArrowLeft') {
                        slidderRef?.slickPrev()
                    }

                    return
                }}
            >
                <CloseIconStyled
                    $image={image}
                    onClick={() => {
                        closeHandler && closeHandler()
                        slidderRef?.innerSlider.list.setAttribute(
                            'tabindex',
                            -1
                        )
                    }}
                />
                {children}
            </ModalWindow>
        </ModalWrapper>
    )
}

const ModalWrapperNew = styled.div<{ open: boolean }>`
    display: ${(props) => (props.open ? 'flex' : 'none')};
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 30;
    overflow-y: scroll;
`
const ModalWindowNew = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    max-height: 100%;
    z-index: 40;
`
const CloseIconStyledNew = styled(CloseIcon)`
    position: fixed;
    top: 30px;
    right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 50;
    fill: ${colors.white};
    stroke: ${colors.dark};
`

export const ModalNew: React.FC<IModalProps> = ({
    closeHandler,
    children,
    isOpen = false,
    slidderRef,
}) => {
    const stopPropagation = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        event.stopPropagation()
    }

    return (
        <ModalWrapperNew open={isOpen} onClick={closeHandler}>
            <ModalWindowNew
                onClick={stopPropagation}
                tabIndex={0}
                onKeyDown={(e: { key: string }) => {
                    if (e.key === 'ArrowRight') {
                        slidderRef?.slickNext()
                    }

                    if (e.key === 'ArrowLeft') {
                        slidderRef?.slickPrev()
                    }

                    return
                }}
            >
                {children}
            </ModalWindowNew>
            <CloseIconStyledNew
                onClick={() => {
                    closeHandler?.()
                    slidderRef?.innerSlider.list.setAttribute('tabindex', -1)
                }}
            />
        </ModalWrapperNew>
    )
}

export const SideModal: React.FC<IModalProps> = ({
    closeHandler,
    children,
    isOpen = false,
}) => {
    return (
        <ModalWrapperNew open={isOpen} onClick={closeHandler}>
            <ModalWindowNew>{children}</ModalWindowNew>
        </ModalWrapperNew>
    )
}
