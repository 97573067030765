import React, { FocusEventHandler } from 'react'

import { Input } from 'components/form/Input'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface EmailInputProps {
    err: string
    placeholder?: TFunction | string
    labelBottom?: number
    onBlur?: FocusEventHandler<HTMLInputElement>
}

export const EmailInput = React.forwardRef<HTMLInputElement, EmailInputProps>(
    ({ err, placeholder, labelBottom, onBlur = () => {} }, ref) => {
        const { t } = useTranslation()

        return (
            <Input
                type="email"
                name="email"
                labelBottom={labelBottom}
                placeholder={placeholder || t('email')}
                ref={ref}
                isValid={err}
                onBlur={onBlur}
                onFocus={(e) => {
                    e.persist()
                    setTimeout(() => {
                        e?.target?.scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                        })
                    }, 200)
                }}
            />
        )
    }
)

EmailInput.displayName = 'EmailInput'
